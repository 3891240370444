'use client';

import React from 'react';
import Container from '@src/components/Shared/Container';
import { CompanyStatsPropTypes, StatsCardTypes } from './TrustedbyStats.types';
import useCounterAnimation from './useCounterAnimation';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
const extractNumbersFromTitles = (topicCollection: StatsCardTypes[]) => {
  return topicCollection.map(item => ({
    start: 0,
    end: parseInt(item?.title?.replace(/\D/g, ''), 10)
  }));
};
const extractSymbolsFromTitle = (title: string) => {
  return title?.replace(/[a-zA-Z0-9\s]/g, '');
};
const CompanyStats: React.FC<CompanyStatsPropTypes> = ({
  data
}) => {
  const counters = extractNumbersFromTitles(data?.topicCollection?.items);
  const {
    onHandleAddCounters
  } = useCounterAnimation({
    counters
  });
  return <Container id={data?.scrollTo?.slice(1)} background='bg-[#FF7A00]' className='4xl:px-[0]' data-sentry-element="Container" data-sentry-component="CompanyStats" data-sentry-source-file="TrustedbyStats.tsx">
      <div className='grid grid-cols-2 gap-[2.3rem] pb-[1rem] pt-[3rem] sm:grid-cols-3 sm:gap-x-[0] sm:pb-[2.3rem] md:grid-cols-6 lg:grid-cols-7'>
        <div className='col-start-1 col-end-3 row-start-1 mb-[1rem]	text-center text-[white] sm:col-end-4 sm:text-center md:col-end-7 lg:col-start-1 lg:col-end-1 lg:m-[unset] lg:self-center lg:text-left'>
          <p className='text-[2.2rem] font-[400] leading-[1] xl:text-[3.2rem]'>{data?.title}</p>
        </div>

        {data?.topicCollection?.items?.map((item, index) => {
        return <div key={item?.sys?.id} className={`${index % 3 !== 0 ? 'sm:border-l-[0.3rem]' : ''} ${index !== 0 ? 'md:border-l-[0.3rem]' : ''} border-b-[0.3rem] border-[#E04F00] sm:border-b-[0] lg:border-l-[0.3rem] xl:border-l-[0.3rem] [&:nth-last-child(-n+2)]:border-b-0 `}>
              <div className='pb-[1.3rem] pr-[0] text-[white] sm:pb-[0] sm:pl-[1rem] sm:pr-[1rem] lg:pl-[2.4rem] lg:pr-[0]'>
                <div className=''>
                  <span ref={onHandleAddCounters} className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'>
                    0
                  </span>
                  <span className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'>
                    {extractSymbolsFromTitle(item?.title)}
                  </span>
                </div>
                <RenderBodyCopy className='text-[1.6rem] font-[400] leading-[1.2] text-[white] md:!text-[1.4rem] lg:!text-[1.6rem] 2xl:!text-[1.8rem] 3xl:!text-[2rem] [&>strong]:font-[600]' bodyCopy={item?.bodyCopy?.json?.content} />
              </div>
            </div>;
      })}
      </div>
    </Container>;
};
export default CompanyStats;