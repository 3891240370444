import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/Sections/ServiceOverview/ServiceOverview.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/Sections/Testimonials/Testimonials.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/Sections/TrustedbyStats/TrustedbyStats.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/Shared/InlineSVG/InlineSVG.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/Shared/ScrollToContainer/ScrollToContainer.tsx");
